import React from 'react'
import { Link } from 'gatsby'
import { graphql } from 'gatsby'
import { FaExternalLinkAlt } from 'react-icons/fa'
import Layout from '../components/layout'
import styled from 'styled-components' 

// eslint-disable-next-line no-extend-native
Array.prototype.sortBy = function(sortfn) {
  const copy = [...this]
  copy.sort(sortfn)
  return copy
}

const LinkList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;
const StyledLink = styled.li`
  font-size: smaller; 
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  &:not(:last-child) {
    border-bottom: 1px solid #ccc;
  }
  a {
    text-decoration: none;
    display: flex;
    margin-left: 0.3em;
    margin-right: 0.3em;
  }
`;

const LinkDate = styled.span`
  font-size: 0.7em;
  ::before {
    content: "[";
  }
  ::after {
    content: "]";
  }
  margin-right: 1em;
  color: #333;
`;
const LinkTitle = styled.span`
`;

const LinkToArticle = ({ relPath, date, title }) => (
  <Link to={relPath}>
    <LinkDate>{date}</LinkDate>
    <LinkTitle>{title}</LinkTitle>
  </Link>
)

const LinkToExternalArticle = ({ absPath, date, title }) => (
  <a href={absPath} target="_blank" rel="noopener noreferrer nofollow">
    <LinkDate>{date}</LinkDate>
    <LinkTitle>{title} <FaExternalLinkAlt /></LinkTitle>
  </a>
)

const Container = styled.div`
  display: flex;
`
const LeftColumn = styled.div`
`
const RightColumn = styled.div`
`

const IndexPage = ({ data }) => (
  <Layout
    metaDescription="Blog Index"
    metaKeywords="software development, functional programming, haskell, cardano, plutus"
  >
    <Container>
      <LeftColumn>
        <img src="/javier_casas.jpg" alt="" />
      </LeftColumn>
      <RightColumn>
        <h1>Last Entries</h1>
        <LinkList>
          {data.allArticle.nodes
            .map((art, index) => (
              <StyledLink key={index}>
                {art.relPath ? (
                  <LinkToArticle {...art} />
                ) : (
                  <LinkToExternalArticle {...art} />
                )}
              </StyledLink>
            ))}
        </LinkList>
      </RightColumn>
    </Container>
  </Layout>
)

export const query = graphql`
  {
    allArticle (
      sort:{
        fields: [date],
        order: DESC
      }
    ) {
      nodes {
        title
        date
        relPath
        absPath
      }
    }
  allFile(
    filter: { sourceInstanceName: { eq: "articles" } }
    sort: {
      fields: [changeTime]
      order: ASC
    }   
  ) {
    edges {
      node {
        childJavascriptFrontmatter {
          frontmatter {
            title
            date
            error
          }
        }
        childMarkdownRemark {
          html
          frontmatter {
            title
            path
            date
          }
          }
          relativePath
        }
      }
    }
  }
`

export default IndexPage
